import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Text from '@components/Text';
import { Link } from 'gatsby'

const Row = props => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    }

    const handleMouseLeave = () => {
        setIsHovered(false);
    }

    return (
        <RowContainer
            style={(isHovered && props.img) ? {
                backgroundImage: `url(${props.img})`,
                paddingLeft: '10px',
                paddingRight: '10px',
            } : undefined}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {props.to ? (
                <NavigableArea to={props.to} />
            ) : null}
            <SmallCell><Text>{props.title}</Text></SmallCell>
            <LargeCell><Text>{props.description}</Text></LargeCell>
            <SmallCell><Text>{props.year}</Text></SmallCell>
        </RowContainer>
    );
}

Row.propTypes = {
    description: PropTypes.string,
    img: PropTypes.string,
    title: PropTypes.string,
    to: PropTypes.string,
    year: PropTypes.string,
}

const NavigableArea = styled(Link)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
`;

const RowContainer = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    border-bottom: 2px solid white;
    padding: 15px 0px 35px 0px;
    background-position: center;
    background-size: cover;
`;

const SmallCell = styled.div`
    flex: 0 0 auto;
    z-index: 2;
`;

const LargeCell = styled.div`
    flex: 1 1 auto;
    text-align: right;
    margin: 0px 20px;
    z-index: 2;
`;

export default Row;