import React from 'react';
import styled from 'styled-components';
import Transition from '@components/Transition';
import Title from '@components/Title';
import WorkItem from '@components/WorkItem';
import zingleImg from '@assets/img/website-art.jpg';
import yvImg from '@assets/img/youth-venture.jpg';
import gatkuImg from '@assets/img/gatku-4.jpg';
import rollerbladeImg from '@assets/img/rollerblade-preview.jpg';
import cityDefenseImg from '@assets/img/city-defense-tile.jpg';
import { Helmet } from 'react-helmet';

const Work = () => (
    <Transition>
        <Helmet>
            <title>Index | Austen Payan</title>
            <link rel="canonical" href="https://austen.io/work/" />
        </Helmet>
        <Title>Case Studies</Title>
        <WorkItem
            description="A communication product connecting businesses and customers."
            img={zingleImg}
            title="Zingle"
            to="/work/zingle"
            year="2019"
        />
        <WorkItem
            description="A check-in system for Youth Venture Teen Centers."
            img={yvImg}
            title="Visit"
            to="/work/visit"
            year="2016"
        />
        <WorkItem
            description="A custom eCommerce and CMS solution for the world's hottest polespear brand."
            img={gatkuImg}
            title="GATKU Polespears"
            to="/work/gatku"
            year="2015"
        />
        <WorkItem
            description="A 360° image rotation plugin for jQuery with over 500 stars on Github."
            img={rollerbladeImg}
            title="Rollerblade.js"
            to="/work/rollerblade"
            year="2014"
        />
        <WorkItem
            description="A work-in-progress Javascript game built using ES2015 via Babel.js."
            img={cityDefenseImg}
            title="City Defense"
            to="/work/city-defense"
            year="2015"
        />
        <Subtitle>Other Work</Subtitle>
        <WorkItem
            description="A minimalist website for an up-and-coming musician."
            title="Mindful Of"
            year="2014"
        />
        <WorkItem
            description="A recipe app for all - from amatuers to culinary influencers, built on Facebook."
            title="Cookpanion"
            year="2016"
        />
        <WorkItem
            description="A creative web experience for a unique financial advisory company."
            title="One Degree Advisors"
            year="2014"
        />
        <WorkItem
            description="A social media network for a community of givers."
            title="Storehouse"
            year="2015"
        />
        <WorkItem
            description="A digital solution for managing complicated hospital workflows."
            title="Codify Health"
            year="2016"
        />
        <WorkItem
            description="A custom classifieds application supporting user accounts and ad platform."
            title="East County Classifieds"
            year="2015"
        />
        <WorkItem
            description="A website for an org providing a fresh, unifying take on a sensitive topic."
            title="Yellow Circles"
            year="2015"
        />
        <WorkItem
            description="A jQuery plugin for beautiful and simple image carousels."
            title="Skippr.js"
            year="2014"
        />
    </Transition>
);

const Subtitle = styled(Title)`
    margin-top: 70px;
`;

export default Work;